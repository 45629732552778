const resourceConfig = {
    pvUrl: 'http://localhost:9003/model/tHdDyJpsV/tileset.json',
    modelUrl: 'http://localhost:9003/model/32YcFn7X/tileset.json',
    domUrl: 'http://localhost:9003/image/wmts/6bgOEG2L/{z}/{x}/{y}',
    // changbuUrl: 'http://localhost:82/zhuangpeishi/changbu/tileset.json',
    tiandituToken: 'a5fe7f3b5f1ec1a03cac7e1986ea4ceb',
    blackEndServerIpNode1: 'http://pv3ds.skyworthpv.com/toModel1',
    blackEndServerIpNode2: 'http://pv3ds.skyworthpv.com/toModel2',
    shareServerIp: 'http://120.77.255.217/share',
    //blackEndServerIp: 'http://172.29.0.79:82',
    //getClientSecret: '2023122001',//生产环境
    getClientSecret: '2023121201',//测试环境
    //getCWTokenUrl: 'https://gateway.skyworthpv.com/api/token',//生产环境
    getCWTokenUrl: 'http://spv5-gateway.skyworthpv.cn/api/token',//户用测试环境
    getCWIACTaskUrl: 'https://gateway.skyworthpv.com/api/uav/uavOrder/getDetail',//工商业生产环境
    getCWHUTaskUrl: 'http://spv5-hpv.skyworthpv.cn/cwgf/api/uav/uavOrder/getHpvDetail',//户用测试环境
    node1Ip: '172.29.0.79',//节点1ip
    node2Ip: '172.29.0.80'//节点2ip
}
export default { resourceConfig };

