import { login, logout, getInfo, appLogin,smsLogin } from '@/api/login'
import { getToken, setToken, removeToken, getAppToken, setAppToken, removeAppToken, getCWToken, setCWToken, removeCWToken } from '@/utils/auth'
import defAva from '@/assets/images/profile.jpg'
import axios from "axios";
import config from '/public/config';

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      cwToken: getCWToken(),
      appToken: getAppToken(),
      name: '',
      avatar: '',
      roles: [],
      permissions: []
    }),
    actions: {
      // 登录
      login(userInfo) {

        const username = userInfo.mobile.trim()
        const password = userInfo.password
        const code = userInfo.code
        const uuid = userInfo.uuid
        return new Promise((resolve, reject) => {
          login(username, password, code, uuid).then(res => {
            setToken(res.token)
            this.token = res.token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 短信登录
      SmsLogin(userInfo) {
        /*debugger*/
        const mobile = userInfo.mobile.trim()
        const password = userInfo.password
        const smsCode = userInfo.smsCode
        const uuid = userInfo.uuid

        const clientId = 'UAV'
        const clientSecret = `${config.resourceConfig.getClientSecret}`
        const scope = 'UAV'
        const data = {
          clientId: clientId,
          clientSecret: clientSecret,
          scope: scope
        }

        return new Promise((resolve, reject) => {
          const cwUrl = `${config.resourceConfig.getCWTokenUrl}`
          axios({
            method: 'post',
            url: cwUrl,
            data: data
          }).then((res) => {
            setCWToken(res.data.data.accessToken)
          })
          smsLogin(mobile, smsCode,password, uuid).then(res => {
            setToken(res.token)
            this.token = ''
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      appLogin(userInfo) {

        const name = userInfo.username.trim()
        const pwd = userInfo.password
        return new Promise((resolve, reject) => {
          appLogin(name, pwd).then(res => {
            setAppToken(res.data.token)
            this.AppToken = res.data.token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          getInfo().then(res => {
            const user = res.user
            const avatar = (user.avatar == "" || user.avatar == null) ? defAva : import.meta.env.VITE_APP_BASE_API + user.avatar;

            if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              this.roles = res.roles
              this.permissions = res.permissions
            } else {
              this.roles = ['ROLE_DEFAULT']
            }
            this.name = user.userName
            this.avatar = avatar;
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          logout(this.token).then(() => {
            this.token = ''
            this.roles = []
            this.permissions = []
            removeToken()
            removeAppToken()
            removeCWToken
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  })

export default useUserStore
