import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const CWTokenKey = 'CW-Token'
const AppTokenKey = 'App-Token'
/**Admin Token */
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

/**ChuangWei Token */
export function getCWToken() {
  return Cookies.get(CWTokenKey)
}

export function setCWToken(CWToken) {
  return Cookies.set(CWTokenKey, CWToken)
}

export function removeCWToken() {
  return Cookies.remove(CWTokenKey)
}

/**APP Token */
export function getAppToken() {
  return Cookies.get(AppTokenKey)
}

export function setAppToken(Apptoken) {
  return Cookies.set(AppTokenKey, Apptoken)
}

export function removeAppToken() {
  return Cookies.remove(AppTokenKey)
}
